import { NotFoundInterface } from './NotFound.interface'
import NF404 from 'src/resources/images/re-404.svg'

const NotFound = ({ testID, type }: NotFoundInterface) => {
    return (
        <div data-testid={testID} data-object-type={type ?? ''} className={`NotFound`}>
            <img src={NF404} alt={'re:cognition'} />
            <a href={'/'}>back home</a>
        </div>
    )
}

export default NotFound
