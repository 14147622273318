// import { useState, useEffect, useContext } from 'react'
import { MenuInterface } from './Menu.interface'
import { Col, Row } from 'react-bootstrap'
import { Logo, MenuItem } from 'src/components/atoms'
import { logoType } from 'src/app-types'

const Menu = ({ testID, type = null, items, setLang, language = 'en' }: MenuInterface) => {
    return (
        <div data-testid={testID} data-object-type={type ?? ''} className={`Menu`}>
            <Row>
                <Col md={6} xs={8}>
                    <div className={'logo-block'}>
                        <Logo size={'s'} type={logoType.regular} />
                    </div>
                </Col>

                <Col md={5} xs={1}>
                    <div className={'menu-block '}>
                        {items?.map((item, index) =>
                            item.showTitle ? <MenuItem key={index} item={item} language={language} /> : null
                        )}
                    </div>
                </Col>
                <Col md={1} xs={1}>
                    <div className={'language-selector'}>
                        <span onClick={() => setLang('nl')} className={language === 'nl' ? 'active' : ''}>
                            NL
                        </span>{' '}
                        |
                        <span onClick={() => setLang('en')} className={language === 'en' ? 'active' : ''}>
                            EN
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Menu
