import { YouTubeEmbedInterface } from './YouTubeEmbed.interface'

const YouTubeEmbed = ({ testID, embedId }: YouTubeEmbedInterface) => {
    return (
        <div data-testid={testID} className={`YouTubeEmbed`}>
            <iframe
                width='100%'
                height='415'
                src={embedId}
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
            />
        </div>
    )
}

export default YouTubeEmbed
