import { createContext } from 'react'
import { AppContextType } from 'src/app-types'

const ctx: AppContextType = {
    data: [],
    news: [],
    language: 'en',
    setLanguage: () => null
}

const AppContext = createContext(ctx)
export { AppContext }
