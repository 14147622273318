/// Colors
export enum colorsDef {
    blue = 'blue',
    purple = 'purple'
}

export const colors: { blue: colorsDef; purple: colorsDef } = {
    blue: colorsDef.blue,
    purple: colorsDef.purple
}

/// Paragraph
export enum paragraphDef {
    markdown = 'markdown',
    plainText = 'plain-text',
    htmlText = 'html-text'
}

export enum paragraphRole {
    default = 'default',
    small = 'small',
    hero = 'hero',
    intro = 'intro'
}

export const paragraphRoleTypes = {
    default: paragraphRole.default,
    small: paragraphRole.small,
    hero: paragraphRole.hero,
    intro: paragraphRole.intro
}

/// Logo
export enum logoType {
    regular,
    payOff,
    muted
}
