import React, { useContext } from 'react'
import { MainInterface } from './Main.interface'
import { Container } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import { ContactForm, Footer, NewsList } from 'src/components/organisms'
import { Menu } from 'src/components/molecules'
import { AppContext } from 'src/context'
import { ScrollIndicator } from 'src/components/atoms'
import { DataType } from 'src/app-types'

const Main = ({ testID }: MainInterface) => {
    const ctx = useContext(AppContext)
    const m: DataType[] | undefined = ctx?.data?.filter((item) => item.type !== 'header')

    return (
        <>
            <ScrollIndicator />
            <Container fluid style={{ padding: 0 }} data-testid={testID} className={'Main'}>
                <Menu items={m} setLang={ctx?.setLanguage} language={ctx.language ?? 'en'} />
                <Outlet />
                <NewsList />
                <ContactForm language={ctx.language ?? 'nl'} />
                <Footer />
            </Container>
        </>
    )
}

export default Main
