import { MenuItemInterface } from './MenuItem.interface'
import { Link } from 'react-router-dom'

const MenuItem = ({ testID, item, language }: MenuItemInterface) => {
    return (
        <div data-testid={testID} className={`MenuItem`}>
            {item.slug && <Link to={`#${item?.slug}`}>{language === 'nl' ? item.title_nl : item.title_en}</Link>}
        </div>
    )
}

export default MenuItem
