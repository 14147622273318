// import { useState, useEffect, useContext } from 'react'
import { NewsPageInterface } from './NewsPage.interface'
import { useContext, useEffect } from 'react'
import { AppContext } from 'src/context'
import { useLocation, ScrollRestoration } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { Paragraph, Heading, YouTubeEmbed } from 'src/components/atoms'
import { paragraphDef, paragraphRoleTypes } from 'src/app-types'
import { getNewsDate } from 'src/lib/Generic'

const NewsPage = ({ testID }: NewsPageInterface) => {
    const ctx = useContext(AppContext)
    const location = useLocation().pathname
    const data = ctx?.news?.find((item) => item.slug === location)
    const language = ctx?.language ?? 'en'

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
    })

    const renderContent = () => {
        if (!data) return null

        const title = ctx.language === 'nl' ? data.title_nl : data.title_en
        const intro = ctx.language === 'nl' ? data.intro_nl : data.intro_en
        const content = ctx.language === 'nl' ? data.content_nl : data.content_en
        const n = data?.date.seconds ? data.date.seconds : 0
        const d = new Date(n * 1000)

        return (
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <Row>
                        <Col md={8} id={'top'}>
                            <Heading type={'h6'} text={getNewsDate(d, language)} />
                            <Heading type={'h2'} text={title} />
                            <Paragraph type={paragraphDef.markdown} content={intro} role={paragraphRoleTypes.intro} />
                        </Col>
                        <Col md={4} className={'img-col'}>
                            <img src={data?.img} className={'hide-small'} alt={title} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className={'content-item'}>
                            {content.map((item, index) => {
                                return (
                                    <div>
                                        {item.text_content && (
                                            <Paragraph type={paragraphDef.markdown} content={item.text_content} />
                                        )}
                                        {item.video_content && <YouTubeEmbed embedId={item.video_content} />}
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Col>
                <Col md={2} />
            </Row>
        )
    }
    return (
        <div data-testid={testID} className={`NewsPage`}>
            {renderContent()}
        </div>
    )
}

export default NewsPage
