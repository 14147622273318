import { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Main } from 'src/components/templates'
import { NewsPage, Home, NotFound, Wait } from 'src/components/pages'
import { AppContext } from 'src/context'
import { ScrollToAnchor } from 'src/components/organisms'
import { useDatabase } from 'src/hooks'

// @ts-ignore
import detectBrowserLanguage from 'detect-browser-language'

const App = () => {
    const l = detectBrowserLanguage().split('-')[0]
    const [language, setLanguage] = useState(l === 'nl' ? 'nl' : 'en')
    const [data, isLoaded] = useDatabase('articles')
    const [news] = useDatabase('news')

    const cutOff = Date.now() >= Date.parse('2024-03-27 12:00:00')

    const renderRoutes = () => {
        if (isLoaded) {
            const arg: any = {
                language,
                data,
                news,
                setLanguage
            }

            return (
                <AppContext.Provider value={arg}>
                    <Routes>
                        <Route element={<Main />}>
                            <Route index path='/' element={<Home />} />
                            {/*<Route index path='/' element={cutOff ? <Home /> : <Wait />} />*/}
                            {/*<Route index path='/hidden' element={<Home />} />*/}
                            <Route index path='/news/:slug' element={<NewsPage />} />
                            <Route index path='*' element={<NotFound />} />
                        </Route>
                    </Routes>
                    <ScrollToAnchor />
                </AppContext.Provider>
            )
        }
    }

    return <BrowserRouter>{renderRoutes()}</BrowserRouter>
}

export default App
