// import { useState, useEffect, useContext } from 'react'
import { FooterInterface } from './Footer.interface'
import { Col, Row } from 'react-bootstrap'
import KRLogo from 'src/resources/images/kr-logo.svg'

const Footer = ({ testID }: FooterInterface) => {
    return (
        <div data-testid={testID} className={`Footer`}>
            <Row>
                <Col md={2} xs={2} />
                <Col md={4} xs={8}>
                    <a className='about-box' href={'https://krewinkel.studio'} target={'_blank'} rel='noreferrer'>
                        <small>envisioned, designed & developed by</small>
                        <img src={KRLogo} alt={'krewinkel.studio'} />
                    </a>
                </Col>
                <Col md={6} xs={2}></Col>
            </Row>
            <Row>
                <Col md={2} xs={2} />
                <Col md={4} xs={8} className={'purple-line'} />
                <Col md={6} xs={2} className={'blue-line'} />
            </Row>
        </div>
    )
}

export default Footer
