import { useState } from 'react'
import { ScrollIndicatorInterface } from './ScrollIndicator.interface'

const ScrollIndicator = ({ testID }: ScrollIndicatorInterface) => {
    const [scroll, setScroll] = useState(0)

    const onScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const maxHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
        const scrolledPercent = (winScroll / maxHeight) * 100
        setScroll(scrolledPercent >= 100 ? 100 : scrolledPercent)
    }

    window.addEventListener('scroll', onScroll)

    return (
        <div data-testid={testID} className={`ScrollIndicator`}>
            <div style={{ width: `${scroll}%` }} className='scrollIndicator'></div>
        </div>
    )
}

export default ScrollIndicator
