import { HeroInterface } from './Hero.interface'
import { Col, Row } from 'react-bootstrap'
import { Heading, Logo, Paragraph } from 'src/components/atoms'
import { ButtonBar } from 'src/components/molecules'
import { logoType, paragraphDef, paragraphRole } from 'src/app-types'

const Hero = ({ testID, type, title, description, item, language = 'en' }: HeroInterface) => {
    return (
        <div data-testid={testID} data-object-type={type ?? ''} className={`Hero`}>
            <Row>
                <Col md={6} />
                <Col md={6} className={'content'}>
                    <Row>
                        <Col md={9}>
                            <Logo size={'xs'} type={logoType.regular} />
                            {title && <Heading type={'h1'} text={title} />}
                            {description && (
                                <Paragraph
                                    type={paragraphDef.markdown}
                                    content={description}
                                    role={paragraphRole.hero}
                                />
                            )}
                            {item?.next && <ButtonBar nxt={item.next} language={language} />}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Hero
