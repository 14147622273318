import moment from 'moment-timezone'

const MONTHS = {
    nl: [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December'
    ],
    en: [
        'Januari',
        'Februari',
        'March',
        'April',
        'May',
        'June',
        'Juli',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],
    de: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
    ]
}

const MONTHSSHORT = {
    nl: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'dec'],
    en: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec'],
    de: ['jan', 'feb', 'mär', 'apr', 'mai', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'dec']
}

const DAYS = {
    nl: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    de: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
}

const DAYSSHORT = {
    nl: ['Zo.', 'Ma.', 'Di.', 'Wo.', 'Do.', 'Vrij.', 'Za.'],
    en: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thur.', 'Fri.', 'Sat.'],
    de: ['So.', 'Mo.', 'Di.', 'Mitt.', 'Do.', 'Frei.', 'Sa.']
}

moment.tz.setDefault('Europe/Amsterdam')

const convertDate = (date: moment.MomentInput, language: string) => {
    let dx = moment(date).tz('Europe/Amsterdam').format('DD-MM-YYYY')
    return dx
}

const dtString = () => {
    let dx = moment().tz('Europe/Amsterdam').format('DD-MM-YYYY')
    return dx
}

const getNewsDate = (date: moment.MomentInput, language: string) => {
    let dx = moment(date).tz('Europe/Amsterdam')
    let d = dx.day()
    let dt = dx.format('D')
    // @ts-ignore
    let dy = DAYS[language][dx.day()].toLowerCase()
    let y = dx.year()
    // @ts-ignore
    let m = MONTHS[language][dx.month()].toLowerCase()

    return `${dt} ${m} ${y}`
}

export { convertDate, getNewsDate, dtString }
