import { LogoInterface } from './Logo.interface'
import Regular from 'src/resources/images/re-cognition-logo.svg'
import PayOff from 'src/resources/images/re-cognition-payoff.svg'
import Muted from 'src/resources/images/re-cognition-muted.svg'
import { Link } from 'react-router-dom'
import { logoType } from 'src/app-types'

const Logo = ({ testID, type = logoType.regular, size = 'm' }: LogoInterface) => {
    let logo

    if (type === logoType.regular) logo = Regular
    if (type === logoType.payOff) logo = PayOff
    if (type === logoType.muted) logo = Muted

    return (
        <div data-testid={testID} data-object-type={type ?? ''} className={`Logo ${size}`}>
            <Link to={'/'}>
                <img src={logo} alt={'re:cognition'} />
            </Link>
        </div>
    )
}

export default Logo
