import { SectionInterface } from './Section.interface'
import { Col, Row } from 'react-bootstrap'
import { Heading, Paragraph, YouTubeEmbed } from 'src/components/atoms'
// @ts-ignore
import Fade from 'react-reveal/Fade'
import { ButtonBar } from 'src/components/molecules'
import { paragraphDef } from 'src/app-types'

const Section = ({ testID, item, imgPosition = 'left', language = 'en' }: SectionInterface) => {
    const renderImage = (additional: string) => {
        if (item.img) {
            return (
                <Col md={6} className={`item-image ${additional}`}>
                    <Fade duration={1500} left={imgPosition === 'left'} right={imgPosition === 'right'}>
                        <img src={item.img} alt={item.title_nl} />
                    </Fade>
                </Col>
            )
        }
        return <Col md={3} />
    }

    const renderVideo = () => {
        if (item.video_content) {
            return <YouTubeEmbed embedId={item.video_content} />
        }
    }

    return (
        <section id={`${item.slug}`} className={'Section'} data-testid={testID}>
            <Row>
                {imgPosition === 'left' && renderImage('hide-small')}
                <Col md={item.img ? 6 : 9} className={'item-content'}>
                    <Fade duration={1200} left={imgPosition === 'right'} right={imgPosition === 'left'}>
                        <>
                            <Heading type={'h2'} text={language === 'nl' ? item.title_nl : item.title_en} />
                            <Paragraph
                                type={paragraphDef.markdown}
                                content={language === 'nl' ? item.content_nl : item.content_en}
                            />
                            {renderVideo()}
                            {item?.next && <ButtonBar nxt={item?.next} language={language} />}
                        </>
                    </Fade>
                </Col>
                {renderImage('show-small')}
                {imgPosition === 'right' && renderImage('hide-small')}
            </Row>
        </section>
    )
}

export default Section
