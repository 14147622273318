import { ButtonBarInterface } from './ButtonBar.interface'
import { Button } from 'src/components/atoms'
import { Link } from 'react-router-dom'
import { colorsDef } from 'src/app-types'

const ButtonBar = ({ testID = '', nxt = null, language = 'en' }: ButtonBarInterface) => {
    const readMore = language === 'nl' ? 'lees verder' : 'read more'

    return (
        <div data-testid={testID} className={`ButtonBar`}>
            {nxt && (
                <Link to={nxt}>
                    <Button type={colorsDef.blue} text={readMore} />
                </Link>
            )}
            <Link to={'#contact'}>
                <Button type={colorsDef.purple} text={'contact'} />
            </Link>
        </div>
    )
}

export default ButtonBar
