import { ParallaxInterface } from './Parallax.interface'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax'

import { Waves } from 'src/components/molecules'
import { Hero } from 'src/components/organisms'
import HandPhone from 'src/resources/images/hand-phone-muted.jpg'

const Parallax = ({ testID, title, item, content, language = 'en' }: ParallaxInterface) => {
    return (
        <div data-testid={testID} className={`Parallax`}>
            <div className={'hide-for-small'}>
                <ParallaxProvider>
                    <ParallaxBanner
                        className={'Banner'}
                        layers={[
                            { image: HandPhone, speed: -20 },
                            {
                                children: (
                                    <>
                                        <Waves />
                                        <Hero title={title} description={content} item={item} language={language} />
                                    </>
                                )
                            }
                        ]}
                    ></ParallaxBanner>
                </ParallaxProvider>
            </div>

            <div className={`show-on-small`}>
                <img src={HandPhone} alt={'re:cognition'} />
                <div className={`mobile-hero`}>
                    <Hero title={title} description={content} item={item} language={language} />
                </div>
            </div>
        </div>
    )
}

export default Parallax
