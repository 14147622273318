import { useState } from 'react'
import { ContactFormInterface } from './ContactForm.interface'
import { Col, Row } from 'react-bootstrap'
import { Button, Heading, InputField, TextArea, ThankYou } from 'src/components/atoms'
import { saveContact } from 'src/lib'
import { languages } from 'src/config'

// @ts-ignore
import Fade from 'react-reveal/Fade'

const ContactForm = ({ testID, language }: ContactFormInterface) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    const [error, setError] = useState(false)
    const [showThanks, setShowThanks] = useState(false)

    // @ts-ignore
    const lang: any = languages[language]

    const date = new Date(Date.now())

    const sendMessage = async () => {
        if (!email || !name || !msg) {
            setError(true)
            return false
        }

        setError(false)

        await saveContact({
            date,
            name,
            email,
            msg
        })

        const post = {
            content: `Message from ${name}`,
            embeds: [
                {
                    title: `${name}, ${email}`,
                    description: msg
                }
            ]
        }

        fetch(
            'https://discord.com/api/webhooks/1195345308939456582/r1Hy9JgZ8rzRZ-KNdXWA3YvP4yXwBMLhxcA-X0DwXMKlFE3VXeUhwPDPBHCOVdQydvfl',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(post)
            }
        )
            .then((response) => response.json())
            .then(() => setShowThanks(true))
            .catch((error) => console.log(error))
            .finally(() => setShowThanks(true))
        setShowThanks(true)
        return true
    }

    const renderContent = () => {
        if (showThanks)
            return (
                <Col md={6}>
                    <ThankYou language={language} />
                </Col>
            )

        return (
            <Col md={6}>
                <Heading type={'h1'} text={lang.contact} />

                <InputField
                    type={'text'}
                    name={'name'}
                    placeholder={lang.name + '*'}
                    value={name}
                    onChange={setName}
                    required={true}
                />
                <InputField
                    type={'text'}
                    name={'email'}
                    placeholder={lang.email + '*'}
                    value={email}
                    onChange={setEmail}
                    required={true}
                />
                <TextArea required={true} name={'message'} placeholder={lang.msg + '*'} value={msg} onChange={setMsg} />

                <Row>
                    {error && (
                        <Col md={6} className={'float-left'}>
                            <p className={'error'}>{lang.error}</p>
                        </Col>
                    )}
                    <Col md={error ? 6 : 12} className={'float-right'}>
                        <Button text={lang.contactButton} action={sendMessage} />
                    </Col>
                </Row>
            </Col>
        )
    }

    return (
        <Fade duration={1500} bottom>
            <div id='contact' data-testid={testID} className={`ContactForm`}>
                <Row>
                    <Col md={3} />
                    {renderContent()}
                    <Col md={3} />
                </Row>
            </div>
        </Fade>
    )
}

export default ContactForm
