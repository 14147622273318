import { useContext } from 'react'
import { NewsListInterface } from './NewsList.interface'
import { AppContext } from 'src/context'
import { Row, Col } from 'react-bootstrap'
import { Heading } from 'src/components/atoms'
import { languages } from 'src/config'

import { NewsItem } from 'src/components/molecules'

const NewsList = ({ testID }: NewsListInterface) => {
    const ctx = useContext(AppContext)

    // @ts-ignore
    const lang: any = languages[ctx.language]

    const n = ctx.news?.sort((a, b) => b.date.seconds - a.date.seconds).slice(0, 10)

    return (
        <div data-testid={testID} className={`NewsList`} id={'news'}>
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <Heading type={'h2'} text={lang.news} />
                    {n?.map((item, index) => {
                        return <NewsItem index={index} item={item} key={index} language={ctx.language} />
                    })}
                </Col>
                <Col md={2} />
            </Row>
        </div>
    )
}

export default NewsList
