// import { useState, useEffect, useContext } from 'react'
import { ThankYouInterface } from './ThankYou.interface'
import { languages } from 'src/config'

const ThankYou = ({ testID, language }: ThankYouInterface) => {
    // @ts-ignore
    const lang: any = languages[language]

    return (
        <div data-testid={testID} className={`ThankYou`}>
            <h1>{lang.thankYou}</h1>
            <p>{lang.thankYouMessage}</p>
        </div>
    )
}

export default ThankYou
