import { WavesInterface } from './Waves.interface'

const Waves = ({ testID }: WavesInterface) => {
    const strokeWidth = 0.25

    return (
        <div data-testid={testID} className={`Waves`}>
            <svg
                className='waves'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                viewBox='0 24 150 28'
                preserveAspectRatio='none'
                shapeRendering='auto'
            >
                <defs>
                    <path
                        id='gentle-wave'
                        d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
                    />
                </defs>
                <g className='parallax'>
                    <use
                        xlinkHref='#gentle-wave'
                        x='48'
                        y='0'
                        stroke='#7F4690'
                        fill='transparent'
                        strokeWidth={strokeWidth}
                    />
                    <use
                        xlinkHref='#gentle-wave'
                        x='48'
                        y='5'
                        stroke='#7F4690'
                        fill='transparent'
                        strokeWidth={strokeWidth}
                    />

                    <use
                        xlinkHref='#gentle-wave'
                        x='48'
                        y='3'
                        stroke='#58AAE1'
                        fill='transparent'
                        strokeWidth={strokeWidth}
                    />
                    <use
                        xlinkHref='#gentle-wave'
                        x='48'
                        y='7'
                        stroke='#58AAE1'
                        fill='transparent'
                        strokeWidth={strokeWidth}
                    />
                </g>
            </svg>
        </div>
    )
}

export default Waves
