const languages = {
    nl: {
        contact: 'Say Hi',
        news: 'Laatste nieuws',
        name: 'Uw naam',
        email: 'Uw email',
        phone: 'Uw telefoonnummer',
        msg: 'Uw bericht',
        contactButton: 'Contact opnemen',
        thankYou: 'Dank u voor uw interesse in re:cognition',
        thankYouMessage: 'We nemen snel contact met u op!',
        error: 'Alle velden moeten ingevuld worden.'
    },

    en: {
        contact: 'Say Hi',
        news: 'News',
        name: 'Your name',
        email: 'Your email',
        phone: 'Your phone number',
        msg: 'Your message',
        contactButton: 'Contact us',
        thankYou: 'Thank you for your interest in re:cognition',
        thankYouMessage: 'We wil reach out to you very soon!',
        error: 'All fields are required.'
    }
}

export default languages
