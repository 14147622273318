// import { useState, useEffect, useContext } from 'react'
import { NewsItemInterface } from './NewsItem.interface'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Heading, Paragraph } from 'src/components/atoms'
import { paragraphDef, paragraphRoleTypes } from 'src/app-types'

// @ts-ignore
import Fade from 'react-reveal/Fade'
import { getNewsDate } from 'src/lib/Generic'

const NewsItem = ({ testID, item, language = 'en', index = 0 }: NewsItemInterface) => {
    const title = language === 'nl' ? item.title_nl : item.title_en
    //  const intro = language === 'nl' ? item?.intro_nl : item?.intro_en
    const content = language === 'nl' ? item.content_nl : item.content_en

    const renderLink = (c: string | any[]) => {
        const prompt = language === 'nl' ? 'lees meer' : 'read more'
        return c.length > 0 ? (
            <Link preventScrollReset={false} to={item.slug}>
                {prompt}
            </Link>
        ) : null
        //return c.length > 0 ? <a href={item.slug}>{prompt}</a> : null
    }

    const intro = language === 'nl' ? item.intro_nl : item.intro_en
    const n = item?.date.seconds ? item.date.seconds : 0
    const d = new Date(n * 1000)

    return (
        <Fade duration={index * 250} bottom>
            <div data-testid={testID} className={`NewsItem`}>
                <Row>
                    <Col md={3} className={'img-col'}>
                        <img src={item?.img} className={'hide-small'} alt={title} />
                    </Col>
                    <Col md={9}>
                        <Heading type={'h6'} text={getNewsDate(d, language)} />
                        <Heading type={'h5'} text={title} />
                        <Paragraph type={paragraphDef.markdown} content={intro} role={paragraphRoleTypes.small} />
                        {renderLink(content)}
                    </Col>
                </Row>
            </div>
        </Fade>
    )
}

export default NewsItem
