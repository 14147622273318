// import { useState, useEffect, useContext } from 'react'
import { InputFieldInterface } from './InputField.interface'

const InputField = ({
    testID,
    name = '',
    value = '',
    type,
    onChange,
    placeholder = '',
    required = true
}: InputFieldInterface) => {
    const changeHandler = (v: string) => {
        onChange(v)
    }
    return (
        <input
            data-testid={testID}
            type={type}
            name={name}
            className={`InputField`}
            value={value}
            placeholder={placeholder}
            onChange={(e) => changeHandler(e.target.value)}
            required={required}
        />
    )
}

export default InputField
