import { ParagraphInterface } from './Paragraph.interface'

import MarkdownView from 'react-showdown'
import { type } from '@testing-library/user-event/dist/type'
import { paragraphRoleTypes, paragraphDef } from 'src/app-types'

const Paragraph = ({
    testID,
    type = paragraphDef.markdown,
    content = '',
    role = paragraphRoleTypes.default
}: ParagraphInterface) => {
    const renderMarkdown = () => {
        if (type === paragraphDef.markdown) {
            return <MarkdownView markdown={content} options={{ tables: true, emoji: true }} />
        }
    }

    const renderPlainText = () => {
        if (type === paragraphDef.plainText) {
            return <p>{content}</p>
        }
    }

    const renderHtmlText = () => {
        if (type === paragraphDef.htmlText) {
            return <div dangerouslySetInnerHTML={{ __html: content }} />
        }
    }

    return (
        <div data-testid={testID} data-object-type={role ?? ''} className={`Paragraph`}>
            {renderMarkdown()}
            {renderPlainText()}
            {renderHtmlText()}
        </div>
    )
}

export default Paragraph
